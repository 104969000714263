import React from 'react'
import {programsData} from "../../data/programsData";
import RightArrow from "../../assets/rightArrow.png";
import "./Programs.css"
const Programs = () => {
  return (
    <div>
      <div className="programs" id="programs">
        {/* PROGRAMS HEADER  */}
        <div className="programs-header">
            <span className='stroke-text'>Explores Our</span>
            <span>Programs</span>
            <span className='stroke-text'>to Shape you</span>
        </div>
        <div className="program-categories">
            {programsData.map((program, index)=>{
         return <div key={index} className="category">
                   {program.image}
                   <span>{program.heading}</span>
                 <span>{program.details}</span>
                 <div className="join-now">
                    <span>Join Now</span>
                    <img src={RightArrow} alt="" />
                 </div>
                 </div>
            })}
        </div>
      </div>
    </div>
  )
}

export default Programs
