import React from 'react'
import Header from '../Header/Header' 
import hero_img_back from "../../assets/hero_image_back.png"
import hero_img from "../../assets/hero_image.png"
import Heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"
import './Hero.css'
import {motion} from "framer-motion"
import CountUp from 'react-countup'; 

const Hero = () => {
    const transition={type:'spring', duration:3}
    const mobile=window.innerWidth<=768? true: false;
  return (
    <div className="hero">
        <div className='blur hero-blur'></div>
        <div className="left-h">
            <Header/>

            {/* THE BEST HERO ADD  */}
            <div className="the-best-add">
                <motion.div
                initial={{left:mobile? '178px':'238px'}}
                whileInView={{left:'8px'}}
                transition={{...transition, type:'tween'}}
                
                ></motion.div>
                <span>The best fintness clube in the town</span>
            </div>

             {/*  HEARO HEADING  */}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Shape </span>
                    <span> Yours</span>
                </div>
                <div>
                    <span>Ideal body</span>
                </div>
                <div>
                    <span>
                        In here we will help you to build your body according to your dream please come over here to full fill dream
                    </span>
                </div>
            </div>
            {/* figuires */}
            <div className="figures">
                <div>
                    <span> 
                    <CountUp delay={1}
                            start={50}
                            end={140}
                            duration={2.75}
                            prefix="+"/>
                    </span>
                    <span>expert coches</span>
                </div>  
                <div>
                    <span>
                    <CountUp delay={1}
                            start={0}
                            end={50}
                            duration={3.3}
                            prefix="+"/>
                    </span>
                    <span>members joined</span>
                </div>  
                <div>
                    <span><CountUp delay={1}
                            start={800}
                            end={987}
                            duration={3.75}
                            prefix="+"/></span>
                    <span>fitness programs</span>
                </div>               
                 
            </div>
            <div className="hero-buttons">
                <buttons className="btn">Get Started</buttons>
                <buttons className="btn">Learn More</buttons>
            </div>
        </div>
       
        <div className="right-h">
            <button className="btn">Join Now</button>
            <motion.div  
            initial={{right:'-1rem'}}
             whileInView={{right:'4rem'}}
            transition={transition} 

            className="heart-rate">
                <img src={Heart} alt="" />
                <span>Heart Rate</span> <span>116 bpms</span>
            </motion.div>
            {/* Hero images */}

            <img src={hero_img} className="hero_image" alt="" />
            <motion.img 
            initial={{right:'11rem'}}
            whileInView={{right:'20rem'}}
           transition={transition} 
            
            src={hero_img_back} alt="" className="hero_image_back"/>
            <motion.div
            initial={{right:'37rem'}}
            whileInView={{right:'28rem'}}
            transition={transition} 

            className="calories">
                <img src={Calories} alt="" />
                <div>
                    <span>Calories Burned </span>
                    <span>220 kcals</span>
                </div>
            </motion.div>
        </div>
    </div>
  )
}

export default Hero
