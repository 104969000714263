import React from 'react'
import img from "../../assets/logo.png"
import bars from "../../assets/bars.png"
import "./Header.css"
import { useState } from 'react'
import { Link } from 'react-scroll'
const Header = () => {

  const mobile=window.innerWidth<=768? true: false;
  const [menuOpened, setMenuOpened]=useState(false)

  return (
    <div className='header' id='home'>
      
      <img src={img} alt="" className='logo' />
      {menuOpened === false && mobile===true?
      <div> <img src={bars} alt="" style={{width:"1.5rem",height:"1.5rem" ,backgroundColor:"var(--appColor)", padding:".5rem" , borderRadius:"5px",
      cursor:"pointer"}}
      onClick={()=>{setMenuOpened(true)}}        
      /> </div>:
      <ul className='header-menu'>
        <li onClick={()=>{setMenuOpened(false)}}><Link onClick={()=>{setMenuOpened(false)}}
        span={true} smooth={true} to="home" activeClass="active" >Home</Link></li>
        <li onClick={()=>{setMenuOpened(false)}}><Link onClick={()=>{setMenuOpened(false)}}
        span={true} smooth={true} to="programs">Programs</Link></li>
        <li onClick={()=>{setMenuOpened(false)}}><Link onClick={()=>{setMenuOpened(false)}}
        span={true} smooth={true} to="reasons">Why Us</Link></li>
        <li onClick={()=>{setMenuOpened(false)}}><Link onClick={()=>{setMenuOpened(false)}}
        span={true} smooth={true} to="plans">Plans</Link></li>
        <li ><Link to="testimonials" onClick={()=>{setMenuOpened(false)}}
        span={true} smooth={true}>Testimonials</Link></li>
      </ul>

    }
      
    </div>
  )
}

export default Header
